import type { ResponsiveProp } from '@lemonenergy/lemonpie/dist/types/utils'
import type { ScreenSize } from '@lemonenergy/lemonpie-themes'
import { useRef } from 'react'
import styled, { css } from 'styled-components'

import { useIntersectionObserver } from '~/hooks/useIntersectionObserver'

interface ImageProps {
  height: ResponsiveProp<number | string>
  src: string
  alt?: string
  title?: string
  isVisible?: boolean
  backgroundSize?: 'cover' | 'contain'
  mobileSrc?: string
}

const Image = styled.div<ImageProps>(
  ({
    backgroundSize = 'cover',
    height,
    src,
    mobileSrc,
    isVisible,
    theme: { media, spacing },
  }) => {
    let heightStyle

    if (typeof height === 'number')
      heightStyle = css`
        min-height: ${spacing(height)};
      `
    else
      heightStyle = Object.entries(height).reduce(
        (acc, [breakpoint, heightSize]) => {
          return css`
            ${acc}
            ${media[breakpoint as ScreenSize]} {
              min-height: ${typeof heightSize === 'number'
                ? spacing(heightSize)
                : heightSize};
            }
          `
        },
        css``,
      )

    return css`
      ${isVisible &&
      css`
        background-image: url(${src});
        background-size: ${backgroundSize};
        background-repeat: no-repeat;
        background-position: center;
      `}

      display: block;
      width: 100%;

      ${heightStyle}

      ${mobileSrc &&
      isVisible &&
      css`
        ${media.only.xs} {
          background-image: url(${mobileSrc});
        }
      `}
    `
  },
)
const ImageWithIntersection = (props: ImageProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  return <Image ref={ref} isVisible={isVisible} {...props} />
}

const StyledImage = styled(ImageWithIntersection)``
StyledImage.displayName = 'StyledImage'
export default StyledImage
